body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width:641px) {
      .container-xl {
        font-size: .9em;
        padding-right:10px;
      }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.form-control::placeholder {
  color: #999;
  font-size: .8rem
}
.form-range {
  padding-top: 1rem !important;
}
.radio-buttons {
  padding-top: .4rem;
}
div.filtercol {
  padding-left:10px;
  padding-right:10px;
}
div.filtercard {
  padding:5px;
}
td.fit {
  white-space: nowrap;
  width:20%;
}
table.show {
  margin-bottom: 0;
}
#shownotemodal {
  width: 90%;
  max-width: 90%;
}
.chatmessage {
  border: gray 1px solid;
  width: max-content;
  padding: 5px;
  border-radius: 10px;
  margin: 4px;
}
.chatmessage.owner {
  background: #cef7ce;
}
.chatmessage.guest {
  background: #ecbec1;
  margin-left: 30px;
}
.member-dropdown {
  width:100%
}
.input-row {
  padding: 3px;
  }
